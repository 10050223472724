import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Tnc = props => {
  return (
    <Layout location={props.location} title="OPTOTAX - T&C">
      <SEO title="Taxation Courses" />
      <div className="banner-body">
        <div className="banner-content">
          <div className="py-2 px-5 tnc">
            <h2 className="mt-5 font-extrabold">Terms and Conditions</h2>
            <h6>Last updated: May 13th, 2020</h6>
            <h4 className="mt-5 font-extrabold">OVERVIEW</h4>
            <p>
              Please read these Terms and Conditions ("Terms") carefully before
              using
              <a href="https://gst.optotax.com" target="_blank">
                {" "}
                https://gst.optotax.com (“OPTOTAX”)
              </a>{" "}
              and/ or
              <a href="https://app.readyb2b.com" target="_blank">
                {" "}
                https://app.readyb2b.com ("READYB2B")
              </a>{" "}
              and/ or RupeeMinder App (“RUPEEMINDER”) operated by OPTOBIZZ
              SOFTWARE Private Limited ("OPTOBIZZ", "us", "we", or "our").
            </p>
            <p>
              Your access to and use of “OPTOTAX” and/ or “READYB2B” and/ or
              “RUPEEMINDER” is conditioned upon your acceptance of and
              compliance with these "Terms". These "Terms" apply to all
              visitors, users and others who access or use “OPTOTAX” and/ or
              “READYB2B” and/ or “RUPEEMINDER”.
            </p>
            <p>
              By accessing or using “OPTOTAX” and/ or “READYB2B” and/ or
              “RUPEEMINDER”, you agree to be bound by these "Terms". If you
              disagree with any part of the "Terms" then you may not access the
              same and leave using the software with immediate effect.
            </p>

            <h5 className="mt-5 uppercase">1. ACCOUNTS</h5>
            <p>
              <ol>
                <li>
                  When you create an account with us, you must provide us
                  information that is accurate, complete, and current at all
                  times. Failure to do so constitutes a breach of the "Terms",
                  which may result in immediate termination of your account on
                  “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER”.
                </li>
                <li>
                  You are responsible for safeguarding the password that you use
                  to access “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER”
                  and for any activities or actions under your password, whether
                  your password is with our software or with a third-party
                  software.
                </li>
                <li>
                  You agree not to disclose your password to any third party.
                  You must notify us immediately upon becoming aware of any
                  breach of security or unauthorized use of your account.
                </li>
                <li>
                  "OPTOBIZZ", at its sole discretion and for any reason deemed
                  fit, might ban a user, business, company from using services
                  of “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” without
                  having to justify such a ban.
                </li>
                <li>
                  You are responsible to add correct GSTIN, e-mail ID and phone
                  number of clients in OPTOTAX.
                </li>
                <li>
                  You are responsible to take consent of parties, whose data is
                  made available on “OPTOTAX” and/ or “READYB2B” and/ or
                  “RUPEEMINDER”, on data sharing norms before using “OPTOTAX”
                  and/ or “READYB2B” and/ or “RUPEEMINDER”. “OPTOTAX” and/ or
                  “READYB2B” and/ or “RUPEEMINDER” reserves the right to seek
                  information on the consent from users if needed. Refer to
                  privacy policy for data sharing norms.
                </li>
              </ol>
            </p>

            <h5 className="mt-5 uppercase">
              2. Disclaimer of Warranties & Limitation of Liability
            </h5>

            <p>
              <ol>
                <li>
                  Though all efforts have been made to ensure the accuracy and
                  currency of the features on “OPTOTAX” and/ or “READYB2B” and/
                  or “RUPEEMINDER”, the same should not be construed as a
                  statement of the relevant (GST) law. In case of any ambiguity
                  or doubts, users are advised to verify/check with the
                  Department(s) and/or other source(s), and to obtain
                  appropriate professional (CA/ Advocate) advice.
                </li>
                <li>
                  Except as specifically provided in this "Terms", “OPTOTAX”
                  and/ or “READYB2B” and/ or “RUPEEMINDER” is provided "as is"
                  without warranty of any kind, express or implied, including
                  but not limited to warranties of performance, merchantability,
                  fitness for a particular purpose, accuracy, omissions,
                  completeness, current-ness and delays.
                </li>
                <li>
                  {" "}
                  Customer agrees that outputs from “OPTOTAX” and/ or “READYB2B”
                  and/ or “RUPEEMINDER” will not, under any circumstances, be
                  considered legal or professional advice and are not meant to
                  replace the experience and sound professional judgment of
                  professional advisors in full knowledge of the circumstances
                  and details of any matter on which advice is sought.
                </li>
                <li>
                  Under no circumstances will "OPTOBIZZ" be liable for any
                  expense, loss or damage including, without limitation,
                  indirect or consequential loss or damage, or any expense, loss
                  or damage whatsoever arising from use, or loss of use, of
                  data, arising out of or in connection with the use of
                  "OPTOTAX.".
                </li>
                <li>
                  "OPTOBIZZ" does not make any claim that access to “OPTOTAX”
                  and/ or “READYB2B” and/ or “RUPEEMINDER” will be
                  uninterrupted, complete or error-free.
                </li>

                <li>
                  "OPTOBIZZ" will not be held liable for Users inability or
                  failure to perform legal or other research-related work or to
                  perform such legal or other research or related work properly
                  or completely, even if assisted by “OPTOTAX” and/ or
                  “READYB2B” and/ or “RUPEEMINDER”, its Affiliates and/or
                  licensors of the foregoing or any decision made or action
                  taken by User in reliance on “OPTOTAX” and/ or “READYB2B” and/
                  or “RUPEEMINDER”.
                </li>
                <li>
                  "OPTOBIZZ" shall not be liable for the use of “OPTOTAX” and/
                  or “READYB2B” and/ or “RUPEEMINDER” by User. And the User
                  shall hold "OPTOBIZZ" harmless and fully indemnified against
                  any claims, costs, damages, loss and liabilities arising out
                  of any such use.
                </li>
                <li>
                  Nothing in this Agreement confers or purports to confer on any
                  third-party any benefit or any right to enforce any term of
                  this Agreement.
                </li>
              </ol>
            </p>
            <h5 className="mt-5 uppercase">3. Links to Other Web Sites</h5>
            <p>
              “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” may contain
              links to third-party websites or Software’s that are not owned or
              controlled by "OPTOBIZZ".
            </p>
            <p>
              "OPTOBIZZ" has no control over, and assumes no responsibility for,
              the content, privacy policies, or practices of any third-party
              websites or Software’s. You further acknowledge and agree that
              "OPTOBIZZ" shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with the use of or reliance on any such
              content, goods or Software’s available on or through any such
              websites or Software’s.
            </p>
            <p>
              We strongly advise you to read the "Terms" and conditions and
              privacy policies of any third-party websites or Software’s that
              you visit.
            </p>

            <h5 className="mt-5 uppercase">4. Privacy Policy</h5>
            <p>
              Please refer to "OPTOBIZZ'"s{" "}
              <a href="/privacy">Privacy Policy document.</a>
            </p>

            <h5 className="mt-5 uppercase">5. Governing Law & Jurisdiction</h5>

            <p>
              These 'Terms" shall be governed by and construed in accordance
              with the Indian laws. Disputes arising in connection with these
              'Terms" (including non-contractual disputes) shall be subject to
              the exclusive jurisdiction of the courts of Hyderabad, Telangana
              (India).
            </p>
            <p>
              All dealings, correspondence and contracts between us shall be
              made or conducted in the English language.
            </p>
            <p>
              Our failure to enforce any right or provision of these "Terms"
              will not be considered a waiver of those rights. If any provision
              of these "Terms" is held to be invalid or unenforceable by a
              court, the remaining provisions of these "Terms" will remain in
              effect. These "Terms" constitute the entire agreement between us
              regarding “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” and
              supersede and replace any prior agreements we might have between
              us regarding “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER”.
            </p>

            <h5 className="mt-5 uppercase">6. Changes</h5>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these "Terms" at any time or in any manner deemed necessary for
              "OPTOBIZZ" to conduct its dayto-day business. Users are therefore
              cautioned to frequently look at our "Terms" to update themselves
              of any changes that "OPTOBIZZ" or “OPTOTAX” and/ or “READYB2B”
              and/ or ”RUPEEMINDER” has incorporated. "OPTOBIZZ" will strive to
              provide updates of this nature over email but our inability to
              send such email(s) for any reason cannot be construed against us.
            </p>
            <p>
              By continuing to access or use “OPTOTAX” and/ or “READYB2B” and/
              or “RUPEEMINDER”, you agree to be bound by the "Terms". If you do
              not agree to the "Terms", please stop using “OPTOTAX” and/ or
              “READYB2B” and/ or “RUPEEMINDER” with immediate effect.
            </p>
            <h5 className="mt-5 uppercase">7. Contact Us</h5>
            <p>
              If you have any questions about this Terms and Conditions, please
              contact us:
              <ul>
                <li>
                  By email:{" "}
                  <a href="mailto:policies@optobizz.com">
                    policies@optobizz.com
                  </a>
                </li>
                <li>
                  By visiting our support portal:{" "}
                  <a href="https://support.readyb2b.com">
                    https://support.readyb2b.com
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Tnc
